import React, { useEffect, useState } from 'react';
import { Popup, useMapEvents } from 'react-leaflet';
import { MarkerAutoPopup } from './MarkerAutoPopup';
import currentLocationIcon from 'assets/leaflet/marker-icon-red.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import { toggleSnackbar } from 'slices/snackbar/snackbarSlice';
import { useDispatch } from 'react-redux';

// marker that marks current location
export const CurrentLocationMarker = (props) => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState(null);
  const mapBounds = [props.mapBounds.northEast, props.mapBounds.southWest]; // must be [northEast, southWest]

  // Toggle snackbar on position being out of bounds
  useEffect(() => {
    if (position && mapBounds && !withinBounds(position, mapBounds)) {
      // dispatch toggleSnackbar action to toggle snack bar
      dispatch(toggleSnackbar({ message: 'move closer to Edmonton Corn Maze to play!', type: 'info' }));
    }
  }, [position]);

  const map = useMapEvents({
    locationfound(e) {
      // only set current position once so effect that listens to position wont keep running
      if (!position) {
        // setPosition([e.latlng.lat, e.latlng.lng]);
        setPosition([53.453, -113.729]); // demo position
      }
    }
  });

  map.locate();
  // only return the current location if found and within bounds.
  return position && withinBounds(position, mapBounds)
    ? (<MarkerAutoPopup icon={CurrentLocationIcon} position={position}><Popup offset={[11, 0]}>You are here</Popup></MarkerAutoPopup>)
    : null;
}

// current location pin icon
const CurrentLocationIcon = L.icon({
  iconUrl: currentLocationIcon,
  shadowUrl: iconShadow
});

// check if a position [lat,long] is out of position. bounds must be : [northEast, southWest]
function withinBounds(position, bounds) {
  // fallback in case null data
  if (!position || !bounds) {
    return true;
  }

  if (position[0] > bounds[0][0] || position[0] < bounds[1][0]) {
    return false; // if latitude is > northEast(north), or < southWest(south)
  } else if (position[1] > bounds[0][1] || position[1] < bounds[1][1]) {
    return false; // if longitude is > northEast(east), or < southWest(west)
  }
  return true;
}
