import { animated, useSpring } from 'react-spring';
import React from 'react';

export const SlideAnimation = (props) => {
  const springStyle = useSpring({
    from: {
      opacity: 0,
      x: props.direction === 'right' || !props.direction ? 1000 : props.direction === 'left' ? -1000 : 0,
      y: props.direction === 'down' ? -1000 : props.direction === 'up' ? 1000 : 0
    },
    delay: props.delay || 0,
    to: { opacity: 1, x: 0, y: 0 },
    config: { duration: 500 }
  })

  return (
    <animated.div style={springStyle} className={props.className}>
      {props.children}
    </animated.div>
  )
}
