import React, { useMemo, useRef, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import checkpointAssistIcon from 'assets/leaflet/marker-icon-white.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';

export const CheckpointAssistMarker = (props) => {
  const [popupMessage, setMessage] = useState('Drag Me!');
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setMessage(`lat, lng: [${marker.getLatLng().lat}, ${marker.getLatLng().lng}]`);
          marker.openPopup();
        }
      },
      add() {
        const marker = markerRef.current;
        marker.openPopup();
      }
    }),
    []
  );

  return (
    <Marker icon={CheckpointAssistIcon} position={props.position} eventHandlers={eventHandlers} ref={markerRef} draggable><Popup offset={[11, 0]}>{popupMessage}</Popup></Marker>
  );
};

// current location pin icon
const CheckpointAssistIcon = L.icon({
  iconUrl: checkpointAssistIcon,
  shadowUrl: iconShadow
});
