import React, { useEffect } from 'react';
import classes from './Turnscreen.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from 'components/Title/Title';
import { MazeButton } from 'components/MazeButton/MazeButton';
import { FadeAnimation } from 'components/Animations';
import { toggleSnackbar } from 'slices/snackbar/snackbarSlice';
import { selectQuestion } from 'slices/question-selection/questionSelectionSlice';
import { selectAnswer } from 'slices/answer-selection/answerSelectionSlice';

export const Turnscreen = () => {
  const dispatch = useDispatch();
  const selectedQuestion = useSelector(selectQuestion) || {};
  const selectedAnswer = useSelector(selectAnswer) || {};

  // guard this route if the selectedQuestion/Answer is empty/null; if so, redirect to /post-selection
  useEffect(() => {
    if (!selectedQuestion.question || !selectedAnswer.answer) {
      // dispatch toggleSnackbar action to toggle snack bar
      dispatch(toggleSnackbar({ message: 'you must select a post first!', redirect: '/post-selection' }));
    }
  }, [selectedQuestion, selectedAnswer]);

  return (
    <FadeAnimation className={classes.turnscreen}>
      <Title className={classes.title} text={`Turn ${questionAnswerTurnPipe(selectedQuestion, selectedAnswer)}.`}/>
      <MazeButton className={classes.button} text="Trivia" route="/post-selection"/>
      <div className={classes.subtext}>Ready for the next question?</div>
    </FadeAnimation>
  );
}

// transform the question and answer into a turn direction in plain english. 'pipe' means 'transform the data'
const questionAnswerTurnPipe = (selectedQuestion, selectedAnswer) => {
  if (!selectedQuestion.correctTurn) {
    return;
  }
  if (selectedAnswer.correct) {
    return selectedQuestion.correctTurn;
  } else {
    // return the opposite of the correctTurn
    return selectedQuestion.correctTurn === 'right' ? 'left' : 'right';
  }
}
