import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: 20,
    backgroundColor: '#E74C3C',
    color: 'white',
    height: 50,
    width: 50,
    '&:hover': {
      backgroundColor: 'rgba(231,76,60,0.75)'
    }
  },
  iconSvg: {
    fontSize: '1.6rem'
  }
}));

export const IconPin = (props) => {
  const classes = useStyles();

  return (
    <Fab aria-label="pin" className={`${props.className} ${classes.icon}`}>
      <RoomIcon className={classes.iconSvg}/>
    </Fab>
  );
}
