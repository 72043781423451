import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Snackbar } from 'components/Snackbar/Snackbar';
import { Home } from 'pages/Home/Home';
import { PostSelection } from 'pages/PostSelection/PostSelection';
import { CategorySelection } from 'pages/CategorySelection/CategorySelection';
import { Turnscreen } from 'pages/Turnscreen/Turnscreen';
import { AnswerSelection } from 'pages/AnswerSelection/AnswerSelection';
import { Map } from 'pages/Map/Map';
import { Footer } from 'components/Footer/Footer';
import { ErrorBoundary } from 'components/Error/ErrorBoundary';
import { Navigator } from 'components/Navigator/Navigator';

export const Pages = (props) => {
  return (
    <ErrorBoundary>
      <Switch>
        <Route path="/post-selection">
          <PostSelection _config={props._config}/>
        </Route>
        <Route path="/category-selection">
          <CategorySelection/>
        </Route>
        <Route path="/answer-selection">
          <AnswerSelection/>
        </Route>
        <Route path="/turn-screen">
          <Turnscreen/>
        </Route>
        <Route path="/map">
          <Map _config={props._config}/>
        </Route>
        <Route path="/">
          <Home/>
        </Route>
      </Switch>
      <Footer/>
      <Snackbar/>
      <Navigator/>
    </ErrorBoundary>
  );
};
