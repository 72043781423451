import React from 'react';

export const CustomButtonBorder = (props) => {
  return (
    <div style={{ position: 'absolute' }}>
      <svg width="246" height="66" viewBox="0 0 246 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="242" height="62" rx="15" stroke="url(#paint0_linear)" strokeWidth="4"/>
        <defs>
          <linearGradient id="paint0_linear" x1="0" y1="33" x2="246" y2="33" gradientUnits="userSpaceOnUse">
            <stop stopColor="#EEC600" stopOpacity="0.8"/>
            <stop offset="0.588542" stopColor="#FFEDA1" stopOpacity="0.8"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
