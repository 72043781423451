import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './AnswerSelection.module.scss';
import { Title } from 'components/Title/Title';
import { Subtitle } from 'components/Subtitle/Subtitle';
import { MazeButton } from 'components/MazeButton/MazeButton';
import { FadeAnimation } from 'components/Animations';
import { toggleSnackbar } from 'slices/snackbar/snackbarSlice';
import { selectQuestion } from 'slices/question-selection/questionSelectionSlice';
import { loadSelectedAnswer } from 'slices/answer-selection/answerSelectionSlice';
import { navigate } from 'slices/navigator/navigatorSlice';

export const AnswerSelection = () => {
  const dispatch = useDispatch();

  // load selected question from the store via selector
  const selectedQuestion = useSelector(selectQuestion) || {};
  console.log(selectedQuestion);

  // categories are dynamically displayed per selected question
  const answers = selectedQuestion.answers || [];

  // guard this route if the selectedQuestion is null; if so, redirect to /post-selection
  useEffect(() => {
    if (!selectedQuestion.question) {
      // dispatch toggleSnackbar action to toggle snack bar
      dispatch(toggleSnackbar({ message: 'you must select a post first!', redirect: '/post-selection' }));
    }
  }, [selectedQuestion]);

  const onClick = (answer) => () => {
    dispatch(loadSelectedAnswer(answer));
    dispatch(navigate({ navigateUrl: '/turn-screen' }));
  };

  return (
    <FadeAnimation className={classes.answer}>
      <div className={classes.textContainer}>
      <Title className={classes.title} text="Question?" />
      <Subtitle className={classes.subtitle} text={selectedQuestion.question}/>
      </div>
      <div className={classes.mazeButtonContainer}>
      {answers.map(
        (answerObject) =>
          <MazeButton
            key={answerObject.answer}
            onClick={onClick(answerObject)}
            className={classes.mazeButton} text={answerObject.answer} />
      )}
      </div>
    </FadeAnimation>
  );
}
