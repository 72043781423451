import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;

const SnackbarContainer = styled.div`
  position: absolute;
  width: auto;
  top: 20px;
  right: 20px;
`;

const FadeIn = styled.div`
  animation: 1s ${fadeInAnimation};
`;

export { SnackbarContainer, FadeIn };
