import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: 20,
    backgroundColor: '#1BB55C',
    color: 'white',
    height: 50,
    width: 50,
    '&:hover': {
      backgroundColor: '#1BB55C'
    },
    '&:focus': {
      backgroundColor: '#1BB55C'
    }
  },
  iconSvg: {
    fontSize: '1.6rem'
  },
  iconText: {
    fontSize: '1.6rem',
    weight: 600
  }
}));

export const IconPinPad = (props) => {
  const classes = useStyles();

  return (
    <Fab aria-label="pinpad" style={props.backspaceIcon ? { width: 120 } : {}}
         className={`${props.className} ${classes.icon}`} onClick={props.onClick}>
      {props.backspaceIcon
        ? <KeyboardBackspaceIcon className={classes.iconSvg}/>
        : <span className={classes.iconText}>{props.number}</span>}
    </Fab>
  );
}
