import { createTheme } from '@material-ui/core/styles';

export const mazeButtonTheme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        // keep borderRadius for rounded shaded overlay element
        border: 'none',
        borderRadius: 17,
        color: 'white',
        letterSpacing: 4,
        textTransform: 'capitalize',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        wordBreak: 'break-word',
        lineHeight: 1.5,
        fontSize: 25,
        height: 66,
        minHeight: 66,
        width: 246,
        minWidth: 246,
        padding: '0px 30px',
        boxShadow: '-9px 7px 9px 0px rgb(0, 0, 0, .12)',
        // pseudo element to apply inner box shadow over the element
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          borderRadius: 17,
          border: '4px solid rgba(0, 0, 0, 0)',
          width: 238, // width(246px) - border on both sides (4px and 4px) = 238
          height: 58, // height(66px) - border on both sides (4px and 4px) = 58
          boxShadow: 'inset -2px 5px 16px 4px rgb(0, 0, 0, .11)'
        }
      },
      endIcon: {
        position: 'absolute',
        right: 32
      }
    }
  }
});
