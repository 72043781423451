import { createSlice } from '@reduxjs/toolkit';

const _ = require('lodash');

export const postSelectionSlice = createSlice({
  name: 'post',
  initialState: {
    postId: null,
    questions: []
  },
  reducers: {
    loadSelectedPost: (state, action) => {
      state.postId = action.payload.id;
      state.questions = action.payload.questions;
    },
    clearSelectedPost: (state) => {
      state.postId = null;
      state.questions = [];
    }
  }
});

// actions
export const { loadSelectedPost, clearSelectedPost } = postSelectionSlice.actions;

// selectors
export const selectPostId = state => state.post.postId;
export const selectQuestions = state => state.post.questions;
export const selectCategories = state => state.post.questions ? _.uniqWith(state.post.questions.map(question => question.category), _.isEqual) : [];

export default postSelectionSlice.reducer;
