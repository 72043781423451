import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { Pages } from 'pages';
import { GlobalStyle, MainTheme } from './theme/ThemeProvider';

const AppContainer = styled.div`
  background-color: ${({ theme }) => theme.primary};
  text-align: center;
  height: 100%;
`;

export const App = (props) => {
  return (
    <ThemeProvider theme={MainTheme}>
      <GlobalStyle/>
      <AppContainer>
        <Router>
          <Pages _config={props._config}/>
        </Router>
      </AppContainer>
    </ThemeProvider>
  )
};
