import { animated, useSpring } from 'react-spring';
import React from 'react';

export const FadeAnimation = (props) => {
  const springStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: props.delay || 0,
    config: { duration: 500 }
  })

  return (
    <animated.div style={springStyle} className={props.className}>
      {props.children}
    </animated.div>
  )
}
