import { createTheme } from '@material-ui/core/styles';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Petrona', sans-serif;
    color: white;
    font-size: 17px;
    font-weight: 700;
    background: rgba(0, 162, 63, .75); // fallback in case browser doesn't support linear-gradient 
    background: linear-gradient(rgba(238, 195, 0, 0.56) 20.97%, rgba(0, 162, 63, 0.7) 70.75%);
    background: -moz-linear-gradient(rgba(238, 195, 0, 0.56) 20.97%, rgba(0, 162, 63, 0.7) 70.75%);
    background: -webkit-linear-gradient(rgba(238, 195, 0, 0.56) 20.97%, rgba(0, 162, 63, 0.7) 70.75%);
  }
`;

const mainPalette = {
  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#d5cc5e'
    },
    secondary: {
      main: '#006ED5'
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#ffffff'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'rgba(255,255,255,0.7)',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: 'rgba(255,255,255,0.7)'
          }
        }
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(38,50,56,0.06)'
      },
      underline: {
        '&:before': {
          borderBottomColor: '#FFFFFF'
        },
        '&:hover:not(.Mui-focused):before': {
          borderBottomColor: '#FFFFFF'
        },
        '&:after': {
          // focused
          borderBottomColor: '#FFFFFF'
        }
      }
    },
    MuiInputBase: {
      input: {
        color: '#FFFFFF'
      }
    },
    MuiInputLabel: {
      root: {
        color: '#FFFFFF',
        '&$error': {
          color: '#404040'
        }
      }
    }
  }
};

const MainTheme = createTheme(mainPalette);

export { MainTheme, GlobalStyle };
