import React from 'react';
import { IconPinPad } from 'components/Icons';
import { Grid } from '@material-ui/core';

export const PostNumberPad = (props) => {
  const { postId, setPostId } = props;

  const backSpace = () => {
    setPostId(postId.slice(0, -1));
  };

  const insertNumber = (number) => () => {
    setPostId(postId + number);
  };

  return (
    <Grid container
          justifyContent="center"
          alignItems="center"
          className={props.className}
          style={{ maxWidth: 200 }}
          spacing={1}>

      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number, i) =>
        <Grid item xs={4} key={i + 100}>
          <IconPinPad key={i} onClick={insertNumber(number)} number={number}/>
        </Grid>
      )}

      <Grid item xs={8}>
      <IconPinPad onClick={backSpace} backspaceIcon/>
      </Grid>
    </Grid>
  );
};
