import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger/src';
import postReducer from 'slices/post-selection/postSelectionSlice';
import questionReducer from 'slices/question-selection/questionSelectionSlice';
import answerReducer from 'slices/answer-selection/answerSelectionSlice';
import snackbarReducer from 'slices/snackbar/snackbarSlice';
import navigatorReducer from 'slices/navigator/navigatorSlice';

// Redux-logger middleware
const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production',
  collapsed: () => true
});

export default configureStore({
  reducer: {
    post: postReducer,
    question: questionReducer,
    answer: answerReducer,
    snackbar: snackbarReducer,
    navigator: navigatorReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});
