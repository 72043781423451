import React from 'react';
import { mazeButtonTheme } from './styled';
import { Button } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { ThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { CustomButtonBorder } from './CustomButtonBorder';

export const MazeButton = (props) => {
  return (
    <div className={props.className} onClick={() => props.onClick && props.onClick() }>
      <CustomButtonBorder/>
      <Link to={ (props.disabled === true || !props.route) ? '#' : props.route } style={{ textDecoration: 'none' }}>
        <ThemeProvider theme={mazeButtonTheme}>
          <Button
            fullWidth
            endIcon={
              props.returnIcon
                ? <KeyboardReturnIcon style={{ color: '#E74C3C', fontSize: '20px' }}/>
                : <ArrowForward style={{ color: '#E74C3C', fontSize: '20px' }}/>
            }
          >
            <span style={props.text.length > 9 ? { fontSize: 15, width: '70%' } : {}}>{props.text}</span>
          </Button>
        </ThemeProvider>
      </Link>
    </div>
  );
}
