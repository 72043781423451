import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classes from './PostSelection.module.scss';
import { Title } from 'components/Title/Title';
import { MazeButton } from 'components/MazeButton/MazeButton';
import { PostNumberPad } from 'components/PostNumberPad/PostNumberPad';
import { PostTextField } from 'components/PostTextField/PostTextField';
import { FadeAnimation } from 'components/Animations/FadeAnimation';
import { loadSelectedPost } from 'slices/post-selection/postSelectionSlice';
import { navigate } from 'slices/navigator/navigatorSlice';
import { toggleSnackbar } from 'slices/snackbar/snackbarSlice';
import { Subtitle } from 'components/Subtitle/Subtitle';

export const PostSelection = (props) => {
  const dispatch = useDispatch();
  const questionConfig = props._config.questionConfig;
  const allPostIds = Object.keys(questionConfig); // list of all post Ids as strings ['10234',...]
  const [postId, setPostId] = useState('');

  const onSubmit = () => {
    if (postId === '') {
      return;
    } else if (!postId || !allPostIds.includes(postId)) {
      dispatch(toggleSnackbar({ message: 'post not found!' }));
      return;
    }

    dispatch(loadSelectedPost({ id: postId, questions: questionConfig[postId] }));
    dispatch(navigate({ navigateUrl: '/category-selection' }));
  }

  return (
    <FadeAnimation className={classes.postSelection}>
      <Title className={classes.title} text="Select the post you're at."/>
      <Subtitle className={classes.subtitle} text="(enter post ID '10234')"/>
      <PostTextField className={classes.postInputField} postId={postId} setPostId={setPostId} onSubmit={onSubmit}/>
      <PostNumberPad className={classes.postNumberPad} postId={postId} setPostId={setPostId}/>
      <MazeButton onClick={onSubmit} className={classes.mazeButton} text="Enter" returnIcon/>
    </FadeAnimation>
  );
}
