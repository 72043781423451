import { createSlice } from '@reduxjs/toolkit';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    open: false,
    message: '',
    type: 'error',
    redirect: null
  },
  reducers: {
    toggleSnackbar: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.redirect = action.payload.redirect || null;
      state.type = action.payload.type || 'error';
    },
    clearSnackbar: (state) => {
      state.open = false;
      state.message = '';
      state.redirect = null;
      state.type = 'error';
    }
  }
});

// actions
export const { toggleSnackbar, clearSnackbar } = snackbarSlice.actions;

// selectors
export const isSnackbarOpen = state => state.snackbar.open;
export const snackbarMessage = state => state.snackbar.message;
export const snackbarMessageType = state => state.snackbar.type;
export const snackbarRedirectRoute = state => state.snackbar.redirect;
export const snackbarMessageRedirect = state => state.snackbar;

export default snackbarSlice.reducer;
