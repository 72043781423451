import React from 'react';
import classes from './ErrorComponent.module.scss';
import { Title } from 'components/Title/Title';
import { MazeButton } from 'components/MazeButton/MazeButton';

export const ErrorComponent = (props) => {
  // change the browser url to the default route
  window.history.replaceState({}, 'Error', '/');

  const reload = () => {
    window.location.href = window.location.origin; // reset to base url, reloading the page
  }

  return (
    <div className={classes.error}>
      <Title className={classes.title} text={'Something Went Wrong.'} />
      <MazeButton className={classes.mazeButton} text="Reload" onClick={reload} route="/" returnIcon/>
    </div>
  );
};
