import React from 'react';
import classes from './Footer.module.scss';
import { Link, withRouter } from 'react-router-dom';
import { IconClose, IconHome, IconPin } from 'components/Icons';
import { FadeAnimation } from 'components/Animations';

export const Footer = withRouter((props) => {
  // hide on home route
  if (props.location.pathname === '/') {
    return null;
  }

  return (
    <FadeAnimation className={classes.footer} delay={800}>
      <Link to="/">
        <IconHome className={classes.icon}/>
      </Link>

      {props.location.pathname !== '/map'
        ? <Link to={{ pathname: '/map', state: { prevPath: location.pathname } }}>
          <IconPin className={classes.icon}/>
        </Link>
        : <Link to={props.location.state ? props.location.state.prevPath : '/'}>
          <IconClose className={classes.icon}/>
        </Link>
      }
    </FadeAnimation>
  );
});
