import React from 'react';
import classes from './Home.module.scss';
import { Title } from 'components/Title/Title';
import { MazeButton } from 'components/MazeButton/MazeButton';
import { FadeAnimation } from 'components/Animations';

export const Home = () => {
  return (
    <FadeAnimation className={classes.homeScreenContainer}>
      <Title className={classes.header} text="Edmonton Corn Maze."/>
      <MazeButton className={classes.triviaButton} text="Trivia" route="/post-selection"/>
      <MazeButton className={classes.mapButton} text="Map" route="/map"/>
      <div className={classes.subtext}>Map uses your location.</div>
    </FadeAnimation>
  );
}
