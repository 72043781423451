import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CloselIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: 20,
    backgroundColor: '#E74C3C',
    color: 'rgba(256, 256, 256, 10)',
    height: 50,
    width: 50,
    '&:hover': {
      backgroundColor: 'rgba(231,76,60,0.75)'
    }
  },
  iconSvg: {
    fontSize: '1.6rem'
  }
}));

export const IconClose = (props) => {
  const classes = useStyles();

  return (
    <Fab aria-label="flag" className={`${props.className} ${classes.icon}`}>
      <CloselIcon className={classes.iconSvg}/>
    </Fab>
  );
}
