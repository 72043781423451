import { createSlice } from '@reduxjs/toolkit';

export const answerSelectionSlice = createSlice({
  name: 'answer',
  initialState: {
    answer: null,
    correct: null
  },
  reducers: {
    loadSelectedAnswer: (state, action) => {
      state.answer = action.payload.answer;
      state.correct = action.payload.correct || null;
    }
  }
});

// actions
export const { loadSelectedAnswer } = answerSelectionSlice.actions;

// selectors
export const selectAnswer = state => state.answer;

export default answerSelectionSlice.reducer;
