import React, { useEffect, useRef } from 'react';
import { Marker } from 'react-leaflet';

// marker that auto opens its popup
export const MarkerAutoPopup = (props) => {
  const leafletRef = useRef();
  const markerPopupTime = 3000;

  useEffect(() => {
    if ('openPopup' in leafletRef.current) {
      leafletRef.current.openPopup();

      // close after a certain time
      setTimeout(() => {
        leafletRef.current && 'closePopup' in leafletRef.current && leafletRef.current.closePopup();
      }, markerPopupTime);
    }
  }, [])
  return <Marker ref={leafletRef} {...props} />
}
