import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import FlagIcon from '@material-ui/icons/Flag';

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: 20,
    backgroundColor: '#E74C3C',
    color: 'rgba(256, 256, 256, 10)',
    height: 50,
    width: 50,
    '&:hover': {
      backgroundColor: 'rgba(231,76,60,0.75)'
    }
  },
  iconSvg: {
    fontSize: '1.6rem'
  }
}));

export const IconFlag = (props) => {
  const classes = useStyles();

  return (
    <Fab onClick={props.onClick} aria-label="flag" className={`${props.className} ${classes.icon}`}>
      <FlagIcon className={classes.iconSvg}/>
    </Fab>
  );
}
