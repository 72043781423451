import React, { useEffect } from 'react';
import { FadeIn, SnackbarContainer } from './styled';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { clearSnackbar } from 'slices/snackbar/snackbarSlice';
import { navigate } from 'slices/navigator/navigatorSlice';

export const Snackbar = () => {
  const dispatch = useDispatch();
  const popupTime = 6000; // time the popup

  // get the open, message and redirect values from the Redux store
  const { open, message, redirect, type } = useSelector(
    state => state.snackbar
  );

  // dispatch action to clear snackbar
  const handleClose = () => {
    dispatch(clearSnackbar());
  }

  // use effect to kick off the set timeout which closes the snackbar
  useEffect(() => {
    if (open) {
      timeoutClose();
    }
  }, [open]);

  // close snackbar manually with setTimeout
  const timeoutClose = () => {
    setTimeout(() => {
      handleClose();
    }, popupTime);
  };

  // if a redirect option was provided, redirect to url
  useEffect(() => {
    if (redirect) {
      dispatch(navigate({ navigateUrl: redirect })); // redirect to URL specified
    }
  }, [redirect]);

  return (
    <SnackbarContainer>
      {open && <FadeIn><Alert onClose={handleClose} severity={type} variant="filled">{message}</Alert></FadeIn>}
    </SnackbarContainer>
  );
}
