import React, { useState } from 'react';
import classes from './Map.module.scss';
import { Title } from 'components/Title/Title';
import { IconFlag } from 'components/Icons';
import { LeafletMap } from 'components/LeafletMap/LeafletMap';
import { SlideAnimation } from 'components/Animations';

export const Map = (props) => {
  const [checkpoints, toggleCheckpoints] = useState(false);

  const handleClick = () => {
    toggleCheckpoints(!checkpoints);
  }

  return (
    <SlideAnimation className={ classes.mapContainer } direction='down'>
      <Title text={'Map.'} className={ classes.title }/>

      <div className={ classes.checkpointFabContainer }>
        <IconFlag className={ classes.checkpointIcon } onClick={handleClick}/>
        Toggle Checkpoints
      </div>

      <div className={ classes.map }>
        <LeafletMap toggleCheckpoints={checkpoints} _config={props._config}/>
      </div>
    </SlideAnimation>
  );
};
