import { createSlice } from '@reduxjs/toolkit';

export const questionSelectionSlice = createSlice({
  name: 'question',
  initialState: {
    selectedQuestion: null
  },
  reducers: {
    loadSelectedQuestion: (state, action) => {
      state.selectedQuestion = action.payload.question;
    },
    clearSelectedQuestion: (state) => {
      state.selectedQuestion = null;
    }
  }
});

// actions
export const { loadSelectedQuestion, clearSelectedQuestion } = questionSelectionSlice.actions;

// selectors
export const selectQuestion = state => state.question.selectedQuestion;
export const selectAnswers = state => state.question.selectedQuestion.answers;

export default questionSelectionSlice.reducer;
