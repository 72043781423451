import React from 'react';
import './LeafletMap.scss';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { CornMazeCheckpoints } from 'components/LeafletCustomMarkers/CornMazeCheckpoints';
import { CurrentLocationMarker } from 'components/LeafletCustomMarkers/CurrentLoactionMarker';
import { CheckpointAssistMarker } from 'components/LeafletCustomMarkers/CheckpointAssistMarker';
import checkpointIcon from 'assets/leaflet/marker-icon-orange.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';

export const LeafletMap = (props) => {
  const checkpointConfig = props._config.checkpointConfig;
  const mapConfig = props._config.mapConfig; // get config from external game-config.js. locations are in [lat, long]

  // bounds with padding
  const bounds = {
    northEast: [mapConfig.mapBounds.northEast[0] - mapConfig.mapBounds.padding, mapConfig.mapBounds.northEast[1] - mapConfig.mapBounds.padding],
    southWest: [mapConfig.mapBounds.southWest[0] + mapConfig.mapBounds.padding, mapConfig.mapBounds.southWest[1] + mapConfig.mapBounds.padding]
  };

  return (
    <MapContainer center={mapConfig.mapCenter}
                  maxBounds={[bounds.northEast, bounds.southWest]}
                  zoom={mapConfig.zoom.initial} minZoom={mapConfig.zoom.min} maxZoom={mapConfig.zoom.max}
                  scrollWheelZoom={mapConfig.zoom.scrollWheelZoom}>
      <TileLayer
        attribution="Edmonton Corn Maze. All Rights Reserved"
        url={`corn-maze-tile-layers/${mapConfig.tileFolder}/{z}/{x}/{y}.png`}/>
      <CurrentLocationMarker mapBounds={bounds}/>
      {props.toggleCheckpoints && <CornMazeCheckpoints checkpoints={checkpointConfig}/>}
      {mapConfig.enableCheckpointAssist && <CheckpointAssistMarker position={mapConfig.mapCenter}/>}
    </MapContainer>
  );
};

// default pin icon
const DefaultIcon = L.icon({
  iconUrl: checkpointIcon,
  shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;
