import React from 'react';
import * as ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import { App } from './App';
import store from './store/store';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE
});

window.RenderApp = (config) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App _config={config}/>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
