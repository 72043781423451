import React from 'react';
import { TextField, ThemeProvider } from '@material-ui/core';
import { MainTheme } from 'theme/ThemeProvider';

export const PostTextField = (props) => {
  const { postId, setPostId } = props;

  const handleChange = (event) => {
    setPostId(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    props.onSubmit();
  }

  return (
    <ThemeProvider theme={MainTheme}>
      <form className={props.className} onSubmit={onSubmit} noValidate autoComplete="off">
        <TextField
          fullWidth
          id="postId"
          label="POST ID"
          type="number"
          name="postId"
          variant="filled"
          onChange={handleChange}
          onInput={e => {
            e.target.value = Math.max(0,
              parseInt(e.target.value, 10)).toString().slice(0, 12);
          }}
          value={postId}
        />
      </form>
    </ThemeProvider>
  );
};
