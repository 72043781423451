import { createSlice } from '@reduxjs/toolkit';

export const navigatorSlice = createSlice({
  name: 'navigator',
  initialState: {
    navigateUrl: '',
    navigate: false
  },
  reducers: {
    navigate: (state, action) => {
      state.navigate = true;
      state.navigateUrl = action.payload.navigateUrl;
    },
    clearNavigation: (state) => {
      state.navigate = false;
      state.navigateUrl = '';
    }
  }
});

// actions
export const { navigate, clearNavigation } = navigatorSlice.actions;

// selectors
export const navigateEnabled = state => state.navigator.navigate;
export const navigateUrl = state => state.navigator.navigateUrl;

export default navigatorSlice.reducer;
