import React from 'react';
import { LayerGroup, Marker } from 'react-leaflet';

// custom checkpoints given an array of coordinates
export const CornMazeCheckpoints = (props) => {
  return (
    <LayerGroup>
      {props.checkpoints.map((coordinate) =>
        <Marker key={coordinate.id} position={coordinate.location}/>
      )}
    </LayerGroup>
  )
}
