import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearNavigation, navigateEnabled, navigateUrl } from 'slices/navigator/navigatorSlice';

export const Navigator = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const redirectEnabled = useSelector(navigateEnabled);
  const redirectUrl = useSelector(navigateUrl);

  // if a redirect option was provided, redirect to url
  useEffect(() => {
    if (redirectEnabled) {
      history.push(redirectUrl.toString()); // redirect to URL specified
      dispatch(clearNavigation()); // clear navigation
    }
  }, [redirectEnabled, redirectUrl]);

  return null;
}
