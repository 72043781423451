import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './CategorySelection.module.scss';
import { Title } from 'components/Title/Title';
import { MazeButton } from 'components/MazeButton/MazeButton';
import { toggleSnackbar } from 'slices/snackbar/snackbarSlice';
import { selectCategories, selectQuestions } from 'slices/post-selection/postSelectionSlice';
import { loadSelectedQuestion } from 'slices/question-selection/questionSelectionSlice';
import { navigate } from 'slices/navigator/navigatorSlice';
import { FadeAnimation } from 'components/Animations';

const _ = require('lodash');

export const CategorySelection = () => {
  const dispatch = useDispatch();

  // get selected posts' categories from the Redux store
  const categories = useSelector(selectCategories) || []; // if first parameter is falsy (empty/null), fall back to []
  const questions = useSelector(selectQuestions) || []; // if first parameter is falsy (empty/null), fall back to []

  // guard this route if the categories/questions of the selected post is empty; if so, redirect to /post-selection
  useEffect(() => {
    if (categories.length === 0 || questions.length === 0) {
      // dispatch toggleSnackbar action to toggle snack bar
      dispatch(toggleSnackbar({ message: 'you must select a post first!', redirect: '/post-selection' }));
    }
  }, [categories, questions]);

  const loadQuestionOfCategory = category => event => {
    const questionsOfCategory = questions.filter((question) => question.category === category);
    const question = _.sample(questionsOfCategory); // one random question from this category

    dispatch(loadSelectedQuestion({ question: question }));
    dispatch(navigate({ navigateUrl: '/answer-selection' }));
  };

  return (
    <FadeAnimation className={classes.category}>
      <Title className={classes.title} text="Select a category."/>
      <div className={classes.mazeButtonContainer}>
      {categories.map(
        (category) => <MazeButton key={category} onClick={loadQuestionOfCategory(category)} className={classes.mazeButton} text={category}/>
      )}
      </div>
    </FadeAnimation>
  );
}
